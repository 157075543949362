import { isPlaceholderTeaserData } from '@hubcms/domain-teaser';
import { GListTeaser, TeaserGridItemParserFn, TeaserGridItemTuple } from '@hubcms/domain-teaser-grid';
import { ListTeaser, ListTeaserPlaceholder } from '@hubcms/ui-teaser';

export const parseListTeaser: TeaserGridItemParserFn<GListTeaser> = (
  item,
  idx,
): TeaserGridItemTuple<typeof ListTeaserPlaceholder | typeof ListTeaser> => {
  if (isPlaceholderTeaserData(item.data.teaserData)) {
    return {
      key: `placeholder-${idx}`,
      Component: ListTeaserPlaceholder,
      componentProps: {
        headerLevel: item.data.headerLevel || 3,
        hasDateTime: item.data.hasDateTime,
      },
    };
  }
  const { url: link, trackingData, ...teaserData } = item.data.teaserData;
  const key = `${teaserData.id}-${idx}`;

  return {
    key,
    Component: ListTeaser,
    componentProps: {
      ...teaserData,
      hasDateTime: item.data.hasDateTime,
      headerLevel: item.data.headerLevel || 3,
      link,
      trackingData: { ...trackingData, ...item.data.trackingData },
    },
  };
};
