import cx from 'classnames';
import { CSSProperties, ElementType, MutableRefObject, PropsWithChildren } from 'react';

import { CssVariable } from '@hubcms/domain-styling';
import { TGridProps } from '@hubcms/domain-teaser-grid';

import styles from './teaser-grid.module.scss';

type TeaserGridItemProps = PropsWithChildren<{
  as?: ElementType | null;
  className?: string;
  gridProps: TGridProps;
  itemRef?: MutableRefObject<HTMLLIElement>;
  style?: CSSProperties;
}>;

function TeaserGridItem({
  gridProps: {
    row = 'auto',
    column = 'auto',
    rowMd,
    columnMd,
    rowLg,
    columnLg,
    minWidth,
    height,
    display,
    displayMd,
    displayLg,
    alignSelf,
    hasPadding = false,
    hasInverseMargin = false,
    hasInverseMarginBlock = hasInverseMargin,
    hasInverseMarginBlockEnd = hasInverseMarginBlock,
    hasInverseMarginBlockStart = hasInverseMarginBlock,
    hasInverseMarginInline = hasInverseMargin,
    hasInverseMarginInlineEnd = hasInverseMarginInline,
    hasInverseMarginInlineStart = hasInverseMarginInline,
    hasInverseMarginSm = false,
    hasInverseMarginBlockSm = hasInverseMarginSm,
    hasInverseMarginBlockEndSm = hasInverseMarginBlockSm,
    hasInverseMarginBlockStartSm = hasInverseMarginBlockSm,
    hasInverseMarginInlineSm = hasInverseMarginSm,
    hasInverseMarginInlineEndSm = hasInverseMarginInlineSm,
    hasInverseMarginInlineStartSm = hasInverseMarginInlineSm,
    hasInverseMarginMd = false,
    hasInverseMarginBlockMd = hasInverseMarginMd,
    hasInverseMarginBlockEndMd = hasInverseMarginBlockMd,
    hasInverseMarginBlockStartMd = hasInverseMarginBlockMd,
    hasInverseMarginInlineMd = hasInverseMarginMd,
    hasInverseMarginInlineEndMd = hasInverseMarginInlineMd,
    hasInverseMarginInlineStartMd = hasInverseMarginInlineMd,
    hasInverseMarginLg = false,
    hasInverseMarginBlockLg = hasInverseMarginLg,
    hasInverseMarginBlockEndLg = hasInverseMarginBlockLg,
    hasInverseMarginBlockStartLg = hasInverseMarginBlockLg,
    hasInverseMarginInlineLg = hasInverseMarginLg,
    hasInverseMarginInlineEndLg = hasInverseMarginInlineLg,
    hasInverseMarginInlineStartLg = hasInverseMarginInlineLg,
    isSquareSm,
  } = {},
  as,
  style = {},
  className,
  children,
  itemRef,
}: TeaserGridItemProps) {
  if (as === null) {
    return null;
  }

  const GridItem = as || 'div';
  style.alignSelf = alignSelf;

  const cssVariables: CssVariable = {
    ...style,
    '--i-row': row,
    '--i-column': column,
    '--i-row-md': rowMd || row,
    '--i-column-md': columnMd || column,
    '--i-row-lg': rowLg || rowMd || row,
    '--i-column-lg': columnLg || columnMd || column,
    '--i-min-width': typeof minWidth === 'string' ? minWidth : minWidth?.sm,
    '--i-min-width-md': typeof minWidth === 'string' ? minWidth : minWidth?.md,
    '--i-min-width-lg': typeof minWidth === 'string' ? minWidth : minWidth?.lg,
    '--i-height': height,
    '--i-display': display,
    '--i-display-md': displayMd,
    '--i-display-lg': displayLg,
  };

  return (
    <GridItem
      className={cx(styles.teaserGridItem, className, {
        [styles.withPadding]: hasPadding,
        [styles.withInverseMarginBlockEnd]: hasInverseMarginBlockEnd,
        [styles.withInverseMarginBlockStart]: hasInverseMarginBlockStart,
        [styles.withInverseMarginInlineEnd]: hasInverseMarginInlineEnd,
        [styles.withInverseMarginInlineStart]: hasInverseMarginInlineStart,
        [styles.withInverseMarginBlockEndSm]: hasInverseMarginBlockEndSm,
        [styles.withInverseMarginBlockStartSm]: hasInverseMarginBlockStartSm,
        [styles.withInverseMarginInlineEndSm]: hasInverseMarginInlineEndSm,
        [styles.withInverseMarginInlineStartSm]: hasInverseMarginInlineStartSm,
        [styles.withInverseMarginBlockEndMd]: hasInverseMarginBlockEndMd,
        [styles.withInverseMarginBlockStartMd]: hasInverseMarginBlockStartMd,
        [styles.withInverseMarginInlineEndMd]: hasInverseMarginInlineEndMd,
        [styles.withInverseMarginInlineStartMd]: hasInverseMarginInlineStartMd,
        [styles.withInverseMarginBlockEndLg]: hasInverseMarginBlockEndLg,
        [styles.withInverseMarginBlockStartLg]: hasInverseMarginBlockStartLg,
        [styles.withInverseMarginInlineEndLg]: hasInverseMarginInlineEndLg,
        [styles.withInverseMarginInlineStartLg]: hasInverseMarginInlineStartLg,
        [styles.squareContentSm]: isSquareSm,
      })}
      ref={itemRef}
      style={cssVariables}
    >
      {children}
    </GridItem>
  );
}

export default TeaserGridItem;
