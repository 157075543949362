import { GNewsletter, TeaserGridItemParserFn } from '@hubcms/domain-teaser-grid';
import { Newsletter } from '@hubcms/ui-newsletter';

export const parseNewsletter: TeaserGridItemParserFn<GNewsletter, typeof Newsletter> = (item, idx) => ({
  Component: Newsletter,
  componentProps: {
    ...item.data,
  },
  key: `newsletter-${idx}`,
});
