import { MouseEventHandler } from 'react';

import { TrackingData } from '@hubcms/domain-tracking';

import { TTeaserGridItem } from './teaser-grid-item';

type AllPropertiesOrNever<T extends Record<string, unknown>> = T | Partial<Record<keyof T, never>>;

type Link = {
  text: string;
  onClick?: MouseEventHandler;
  trackingData?: TrackingData;
} & AllPropertiesOrNever<{ url: string; target?: '_blank' | '_self' }>;

export type GTitle = TTeaserGridItem<
  'title',
  {
    text: string;
    headerLevel?: number;
    size?: 'sm' | 'lg';
    subLink?: Link | null;
    link?: Link | null;
  }
>;

type Logo = {
  url: string;
  alt?: string;
};

export type GLogo = TTeaserGridItem<
  'logo',
  {
    text?: string;
    logo: Logo;
  }
>;

export type GTitleOrGLogo = GTitle | GLogo;

export function isGTitle(title: GTitleOrGLogo): title is GTitle {
  return title.type === 'title';
}

export function isGLogo(logoTitle: GTitleOrGLogo): logoTitle is GLogo {
  return logoTitle.type === 'logo';
}
