import { GList, TeaserGridItemParserFn } from '@hubcms/domain-teaser-grid';

import { Areas } from '../areas';

export const parseList: TeaserGridItemParserFn<GList, Exclude<typeof Areas.list, undefined>> = (item, idx) => ({
  Component: Areas.list,
  componentProps: {
    title: item.data.title,
    items: item.data.items,
    listProps: {
      layout: item.data.layout,
      isOrdered: item.data.isOrdered,
      verticalAlign: item.data.verticalAlign,
      mdTwoColumns: item.data.mdTwoColumns,
    },
    listDirection: item.data.listDirection,
    hasItemSeparator: item.data.hasItemSeparator,
    scroll: item.data.scroll,
    theme: item.data.theme,
    isTransparentTheme: item.data.isTransparentTheme,
  },
  key: `list-${idx}`,
});
