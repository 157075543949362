import type { MutableRefObject } from 'react';

import { useIntersectionObserver } from '@hubcms/utils-browser';
import { useEffect, useRef } from 'react';

import { ScrollWrapperContextValue, useScrollWrapperContext } from './context';

type ScrollableItemProps = {
  context: ScrollWrapperContextValue;
  index: number;
  render: (itemRef?: MutableRefObject<HTMLLIElement>) => JSX.Element;
};

function ScrollableItem({ context, index, render }: ScrollableItemProps) {
  const { intersection, rootNode } = context;
  const { setIntersections } = intersection;

  const itemRef = useRef() as MutableRefObject<HTMLLIElement>;

  const ioEntry = useIntersectionObserver(itemRef, { threshold: 1, root: rootNode, rootMargin: '10%' });

  useEffect(() => {
    setIntersections?.(index, ioEntry?.isIntersecting ?? false);
  }, [index, ioEntry?.isIntersecting, setIntersections]);

  return render(itemRef);
}

type ScrollableItemWrapperProps = Omit<ScrollableItemProps, 'context'>;

function ScrollableItemWrapper({ index, render }: ScrollableItemWrapperProps) {
  const wrapperContext = useScrollWrapperContext();

  if (wrapperContext) {
    return <ScrollableItem context={wrapperContext} index={index} render={render} />;
  }

  return render();
}

export default ScrollableItemWrapper;
