import { isPlaceholderTeaserData } from '@hubcms/domain-teaser';
import { GDefaultTeaser, TeaserGridItemParserFn, TeaserGridItemTuple } from '@hubcms/domain-teaser-grid';
import { DefaultTeaser, DefaultTeaserPlaceholder } from '@hubcms/ui-teaser';

export const parseDefaultTeaser: TeaserGridItemParserFn<GDefaultTeaser> = (
  item,
  idx,
): TeaserGridItemTuple<typeof DefaultTeaserPlaceholder | typeof DefaultTeaser> => {
  if (isPlaceholderTeaserData(item.data.teaserData)) {
    const tuple: TeaserGridItemTuple<typeof DefaultTeaserPlaceholder> = {
      key: `placeholder-${idx}`,
      Component: DefaultTeaserPlaceholder,
      componentProps: {
        headerLevel: item.data.headerLevel || 3,
        hasDuration: item.data.hasDateTime,
        isImageHidden: item.data.isImageHidden,
        hasIntro: !!item.data.hasIntro,
        hasLabel: false,
        imageFormat: item.data.imageFormat,
        isHighlighted: item.data.isHighlighted,
        orientation: item.data.orientation,
        size: item.data.size,
      },
    };
    return tuple;
  }
  const { url: link, trackingData, ...teaserData } = item.data.teaserData;
  const key = `${teaserData.id}-${idx}`;

  return {
    key,
    Component: DefaultTeaser,
    componentProps: {
      ...teaserData,
      headerLevel: item.data.headerLevel || 3,
      hasDateTime: item.data.hasDateTime,
      isImageHidden: item.data.isImageHidden,
      hasInset: item.data.hasInset,
      hasIntro: item.data.hasIntro,
      link,
      imageFormat: item.data.imageFormat,
      imageSizes: item.data.imageSizes,
      isFullHeight: item.data.isFullHeight,
      isHighlighted: item.data.isHighlighted,
      isImagePriority: item.data.isPriority,
      orientation: item.data.orientation,
      size: item.data.size,
      style: item.data.style,
      trackingData: { ...trackingData, ...item.data.trackingData },
    },
  };
};
